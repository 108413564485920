import {
  Alert,
  Checkbox,
  Col,
  Container,
  ErrorText,
  FAB,
  Loading,
  NotificationActions
} from '@elotech/components';
import {
  ParametroItbiService,
  TipoItbiService,
  withService
} from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ParametroItbiSelect from './ParametroItbiSelect';
import TipoItbiAise from './TipoItbiAise';

const errorMessages = {
  tipoItbi: 'Campos Obrigatórios',
  tipoItbiAise: 'Tipo ITBI do Aise é obrigatório',
  descricao: 'Descrição é obrigatório',
  parametroItbi: 'Parâmetro de ITBI é obrigatório'
};

const tipoItbiAiseFinanciado = 2;
const tipoItbiAiseComAnuencia = 3;

export class TiposItbiFormPage extends React.Component {
  state = {
    loading: false,
    tipoItbi: {
      visivel: false,
      visivelCidadao: false,
      permiteTotalmenteFinanciado: false,
      descricao: '',
      tipoItbiAise: undefined,
      parametroItbi: undefined
    },
    error: {
      tipoItbiAise: false,
      descricao: false,
      parametroItbi: false
    },
    listTiposITBIAise: [],
    parametrosItbi: [],
    showPermiteTotalmenteFinanciado: false
  };

  static propTypes = {
    tipoItbiService: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      tipoItbiService,
      parametroItbiService,
      match: { params }
    } = this.props;

    tipoItbiService.getTipoItbiAise().then(response => {
      this.setState({ listTiposITBIAise: response.data });
    });

    parametroItbiService.findAllAtivos().then(response => {
      this.setState({ parametrosItbi: response.data });
    });

    if (params.id) {
      this.setState({ loading: true });
      tipoItbiService
        .getTipoItbi(params.id)
        .then(response => {
          const tipoItbi = { ...response.data };

          this.setState({
            tipoItbi: tipoItbi,
            loading: false
          });

          this.setState({
            showPermiteTotalmenteFinanciado: this.getShowPermiteTotalmenteFinanciado(
              tipoItbi.tipoItbiAise
            )
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          Alert.error(
            {
              title: 'Erro ao carregar tipo de ITBI'
            },
            error
          );
        });
    }
  }

  onChangeField = event => {
    const { name, value, checked, type } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    this.setState(state => ({
      tipoItbi: {
        ...state.tipoItbi,
        [name]: newValue
      },
      error: {
        ...state.error,
        [name]: false
      }
    }));
  };

  handleChangeSelectAise = event => {
    const { value } = event.target;
    const { listTiposITBIAise = [] } = this.state;
    const tipoItbiAiseSelect = listTiposITBIAise.find(
      item => item.id === value
    );

    const showPermiteTotalmenteFinanciado = this.getShowPermiteTotalmenteFinanciado(
      tipoItbiAiseSelect
    );

    const { tipoItbi } = this.state;
    if (!showPermiteTotalmenteFinanciado) {
      tipoItbi.permiteTotalmenteFinanciado = false;
    }

    this.setState(state => ({
      tipoItbi: {
        ...tipoItbi,
        tipoItbiAise: tipoItbiAiseSelect
      },
      showPermiteTotalmenteFinanciado: showPermiteTotalmenteFinanciado,
      error: {
        ...state.error,
        tipoItbiAise: false
      }
    }));
  };

  handleChangeParametroItbi = event => {
    const { value } = event.target;
    const { parametrosItbi = [] } = this.state;
    const parametroItbiSelected = parametrosItbi.find(
      item => item.id === +value
    );

    this.setState(state => ({
      tipoItbi: {
        ...state.tipoItbi,
        parametroItbi: parametroItbiSelected
      },
      error: {
        ...state.error,
        parametroItbi: false
      }
    }));
  };

  onValidateDados = tipoItbi => {
    const error = {};
    error.descricao = !tipoItbi.descricao || !tipoItbi.descricao.trim();
    error.tipoItbiAise = !tipoItbi.tipoItbiAise;
    error.parametroItbi = !tipoItbi.parametroItbi;
    const valid = !Object.values(error).includes(true);
    if (!valid) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos Obrigatórios!'
      });
      this.setState({ error: error });
    }
    return valid;
  };

  onSave = () => {
    const { tipoItbi } = this.state;
    if (!this.onValidateDados(tipoItbi)) {
      return;
    }

    this.setState({ loading: true });
    this.props.tipoItbiService
      .save(tipoItbi)
      .then(response => {
        if (response.data) {
          this.setState({ loading: false });
          Alert.success({ title: 'Tipo de ITBI salvo com sucesso!' });
        }
        this.props.history.replace('/tipos-itbi');
      })
      .catch(error => {
        this.setState({ loading: false });
        Alert.error({ title: 'Não foi possível salvar tipo de ITBI' }, error);
      });
  };

  getShowPermiteTotalmenteFinanciado(tipoItbiAiseSelect) {
    return (
      tipoItbiAiseSelect.codigo === tipoItbiAiseFinanciado ||
      tipoItbiAiseSelect.codigo === tipoItbiAiseComAnuencia
    );
  }

  render() {
    const {
      loading,
      tipoItbi,
      listTiposITBIAise,
      error,
      parametrosItbi,
      showPermiteTotalmenteFinanciado
    } = this.state;
    return (
      <Container title="Tipos de ITBI" icon="building">
        <Loading loading={loading} />
        <div className="row">
          <Col md={4}>
            <TipoItbiAise
              tiposItbiAise={tipoItbi.tipoItbiAise}
              listTiposITBIAise={listTiposITBIAise}
              onChangeSelect={this.handleChangeSelectAise}
              error={error}
              errorMessages={errorMessages}
            />
          </Col>
          <Col md={4}>
            <ParametroItbiSelect
              options={parametrosItbi}
              currentValue={tipoItbi.parametroItbi}
              onSelect={this.handleChangeParametroItbi}
              error={error}
              errorMessages={errorMessages}
            />
          </Col>
          <Col md={4}>
            <Checkbox
              data-test-id="checkboxVisivel"
              id="check-visivel"
              name="visivel"
              checked={tipoItbi.visivel}
              onChange={e => {
                const { checked } = e.target;
                this.setState(prev => {
                  return {
                    ...prev,
                    tipoItbi: {
                      ...prev.tipoItbi,
                      visivel: checked,
                      visivelCidadao: !checked
                        ? checked
                        : prev.tipoItbi.visivelCidadao
                    }
                  };
                });
              }}
              label={'Visível '}
              className="form-group"
            />
            <Checkbox
              data-test-id="checkboxVisivelCidadao"
              id="check-visivelCidadao"
              name="visivelCidadao"
              checked={tipoItbi.visivelCidadao}
              onChange={e => {
                const { checked } = e.target;
                this.setState(prev => ({
                  ...prev,
                  tipoItbi: {
                    ...prev.tipoItbi,
                    visivelCidadao: checked
                      ? prev.tipoItbi.visivel && checked
                      : false
                  }
                }));
              }}
              label={'Visível para cidadão'}
              className="form-group"
            />
          </Col>
        </div>
        <div className="row">
          {showPermiteTotalmenteFinanciado && (
            <div className="form-group col-md-3">
              <Checkbox
                data-test-id="checkboxPermiteTotalmenteFinanciado"
                id="check-itbi-financiado"
                name="permiteTotalmenteFinanciado"
                checked={tipoItbi.permiteTotalmenteFinanciado}
                label={'Permite ITBI Totalmente Financiado'}
                onChange={this.onChangeField}
                className="form-group"
              />
            </div>
          )}
          <div
            className={
              showPermiteTotalmenteFinanciado
                ? 'form-group col-md-7'
                : 'form-group col-md-10'
            }
          >
            <label htmlFor="descricao" className="label">
              Descrição
            </label>
            <input
              data-test-id="inputDescricao"
              id="descricao"
              type="text"
              className={error.descricao ? 'error' : ''}
              value={tipoItbi.descricao || ''}
              name="descricao"
              onChange={this.onChangeField}
            />
            {error.descricao && (
              <ErrorText>{errorMessages.descricao}</ErrorText>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-10">
            <label htmlFor="observacao" className="label">
              Observação
            </label>
            <textarea
              data-test-id="inputObservacao"
              id="observacao"
              value={tipoItbi.observacao || ''}
              name="observacao"
              onChange={this.onChangeField}
            />
          </div>
        </div>
        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            icon="check"
            iconColor="white"
            title="Salvar"
            onClick={this.onSave}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  tipoItbiService: TipoItbiService,
  parametroItbiService: ParametroItbiService
})(TiposItbiFormPage);

const connectComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ComponentWithService);

export default connectComponent;
